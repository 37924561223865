

class DTool extends AnkaPanAPI.Base {

    constructor(depthPlugin, type) {
        super()
        this.type = type
        this._depthPlugin = depthPlugin

        let sampleClass = AnkaScalable.SPolygon
        if (type === 'Point') {
            sampleClass = AnkaScalable.SPoint
        } else if (type === 'Line') {
            sampleClass = AnkaScalable.SLine
        } else if (type === 'Polygon') {
            sampleClass = AnkaScalable.SPolygon
        }

        let points = []
        let attributes = {}
        this.gdh = new AnkaScalable.GeomDataHolder(sampleClass, points, attributes)
        this.gdh.setStatus(AnkaScalable.GeomDataHolder.STATUS.DRAWING)
        AnkaScalable.GeometryObserver.addGeometries([this.gdh])
    }

    removeLastPoint() {
        this.gdh.removeLastPoint()
        this.throwEvent({ type: "onStatusChange", feature: this.gdh, status: 'LAST_POINT_REMOVED', drawType: this.type })
    }

    addPoint(point) {
        this.gdh.addPoint(point);
        this.onPointAdd(point)
    }

    onPointAdd(point) {
        let { lon, lat, alt } = point
        this.throwEvent({ type: "onStatusChange", feature: this.gdh, status: 'POINT_ADDED', drawType: this.type, lon, lat, alt })
    }

    endDraw() { implementError() }

    badEnd() {
        const gdh = this.gdh
        let isSuccessful = false;
        gdh.setStatus(AnkaScalable.GeomDataHolder.STATUS.BAD_COMPLETED)
        AnkaScalable.GeometryObserver.removeGeometry(this.gdh)
        this.throwEvent(endEventCreator(this.type, isSuccessful, gdh))
    }
}


export { DTool }