import { DepthPlugin } from './DepthPlugin'
import { endEventCreator } from './EventCreator'
import { DTool } from './DTool'

class DLineTool extends DTool {

    constructor(depthPlugin, layer) {
        super()
        this.type = 'Line'
        this._depthPlugin = depthPlugin
        let sampleClass = AnkaScalable.SLine
        let points = []
        let attributes = {}
        this.gdh = new AnkaScalable.GeomDataHolder(sampleClass, points, attributes)
        this.gdh.setStatus(AnkaScalable.GeomDataHolder.STATUS.DRAWING)
        AnkaScalable.GeometryObserver.addGeometries([this.gdh])
    }

    endDraw() {
        const gdh = this.gdh
        const pointNumber = gdh.getPointLength()
        const GeomDataHolder = AnkaScalable.GeomDataHolder
        let isSuccessful = false
        if (pointNumber < 2) {
            gdh.setStatus(GeomDataHolder.STATUS.BAD_COMPLETED)
            AnkaScalable.GeometryObserver.removeGeometry(this.gdh)
        } else {
            isSuccessful = true
            gdh.setStatus(GeomDataHolder.STATUS.COMPLETED)
        }
        this.throwEvent(endEventCreator(this.type, isSuccessful, gdh))
    }

}

export { DLineTool }