

let FRAGMENT_STR = `
#include <packing>
varying vec2 vUv;
uniform float u_heading;
uniform vec3 mouseScreenPosition;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;
uniform mat4 u_sphere_inverse;
uniform float cameraNear;
uniform float cameraFar;
uniform sampler2D t_depth;
uniform float rangeAlpha;
uniform bool isForDistance;
uniform bool showDepth;
const float PI = 3.1415926535897932384626433832795;
const float SCALE = 1000.0;

bool isDepthValid(vec4 depth_px) {
    if(depth_px.a < 0.2) return false;
    return !(depth_px.r == 0.0 && depth_px.b == 0.0 && depth_px.g == 0.0);
}

bool isPositionValid(vec3 position) {
    return !(position.r == 0.0 && position.b == 0.0 && position.g == 0.0);
}

float packColor(vec3 color) {
    float red = color.r;
    float green = color.b;
    float blue = color.g;
    return blue + green * 256.0 + red * 256.0 * 256.0;
}

vec3 getDepthCoord(vec3 color, vec2 uv) {
    float RADIAN = (u_heading  - (180.0+90.0)) / 180.0 * PI;
    float dist_from_cam = packColor(color * 255.0) / SCALE;
    float pitch_in_rad = (uv.y - 0.5) * PI;
    float yaw_in_rad = (uv.x * (PI * 2.0)) + RADIAN;
    float neighbour = cos(pitch_in_rad) * dist_from_cam;
    float y = sin(pitch_in_rad) * dist_from_cam;
    float x = cos(yaw_in_rad) * neighbour;
    float z = sin(yaw_in_rad) * neighbour;
    return vec3(x,y,z);
}


void main() {

    float dist = distance(gl_FragCoord.xy, mouseScreenPosition.xy);

    if(isForDistance) {

        if(dist < 1.0) {
            vec4 depthTexture = texture2D(t_depth, vUv);
            if(!isDepthValid(depthTexture)) {
                gl_FragColor = vec4(0.0, 0.0, 0.0,1.0);
                return;
            }

            float depth_distance = packColor(depthTexture.rgb * 255.0) / SCALE;
            if(depth_distance < 2.35) {
                gl_FragColor = vec4(0.0, 0.0, 0.0,1.0);
                return;
            }
            gl_FragColor = depthTexture;
        } else {
            gl_FragColor = vec4(0.0, 0.0, 0.0,0.0);
            return;
        }

        return;
    }

    if(!isPositionValid(mouseScreenPosition)) {
        discard;
    }

    vec4 depthTexture = texture2D(t_depth, vUv);
    if(isDepthValid(depthTexture)) {
        vec3 depth_coord = getDepthCoord(depthTexture.rgb, vUv);
        float depth_distance = packColor(depthTexture.rgb * 255.0) / SCALE;
        float dist = distance(depth_coord, mouseScreenPosition);
        float CIRCLE_RADIUS = 0.6;
        if(dist < CIRCLE_RADIUS) {
            vec4 circleColor = vec4( 1.0, 1.0, 0.0, 1.0 );
            vec4 circleColor2 = vec4( 1.0, 1.0, 1.0, 0.1 );
            float lineSize = CIRCLE_RADIUS / 3.0;
            float thLine = mod(dist,lineSize);
            gl_FragColor.rgba = mix(circleColor2, circleColor, step(lineSize / 2.0,thLine));
        } else {
            // discard;

            if(showDepth) {
                gl_FragColor.rgb = depthTexture.rgb;
                gl_FragColor.a = rangeAlpha;
            } else {
                discard;
            }
        }

    } else {
        discard;
    }
}
`

export { FRAGMENT_STR }